<template>
  <div>
    <breadcrumb-nav>
      <template v-slot:firstMenu>营销管理</template>
      <template v-slot:secondMenu>推广商品</template>
    </breadcrumb-nav>
    <el-card>
      <el-table style="width: 100%" border :data="list">
        <el-table-column type="index" label="序号" width="80px" align="center">
        </el-table-column>
        <el-table-column prop="imageUrl" label="商品图标" width="80px">
          <template slot-scope="{ row }">
            <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px" />
          </template>
        </el-table-column>
        <el-table-column prop="goodsName" label="商品名称">
        </el-table-column>
        <el-table-column prop="title" label="显示标题" width="120px">
        </el-table-column>
        <el-table-column prop="desc" label="显示详情">
        </el-table-column>
        <el-table-column prop="sence" label="显示场景" width="120px">
          <template slot-scope="scope">{{ scope.row.sence | senceLabel }}</template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="200px">
          <template slot-scope="scope">
            <p>推广状态：
              <el-switch v-model="scope.row.status" :active-value=1
                         :inactive-value=2 @change="changeAdGoodsState(scope.row)">
              </el-switch>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="prop" label="操作" width="100px">
          <template slot-scope="{row}">
            <el-row>
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="update(row)"
              >更改</el-button>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[5, 10]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      <el-dialog
        :title="form.id ? '更改显示场景' : '添加'"
        :visible.sync="dialogFormVisible"
      >
        <el-form style="width: 80%" :model="form" :rules="rules" ref="form">
          <el-form-item label="类型" label-width="100px" prop="type">
            <el-select v-model="form.sence" style="width:100%" placeholder="请选择显示场景">
              <el-option
                v-for="item in senceTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addOrUpdate"
          >确 定</el-button
          >
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import {
  getAdGoodsListRequest, updateAdGoodsRequest,
  updateAdGoodsStatusRequest
} from '../../../network/goods'
import BreadcrumbNav from '../../common/BreadcrumbNav'

export default {
  name: 'AdGoods',
  components: {
    BreadcrumbNav
  },
  filters: {
    senceLabel (value) {
      switch (value) {
        case 1:
          return '广告失败'
        case 2:
          return '分享'
        default:
          return '广告失败'
      }
    }
  },
  data () {
    return {
      listQuery: {
        page: 1,
        size: 10,
        sence: 0,
        status: 0
      },
      // 总共数据条数
      total: 0,
      // 列表展示的数据
      list: [],
      senceTypes: [
        {
          label: '广告失败',
          value: 1
        },
        {
          label: '分享',
          value: 2
        }
      ],
      dialogFormVisible: false,
      form: {
        sence: 1
      },
      rules: {
        // require:必须要校验字段（前面五角星有关系）  message 提示信息    trigger:用户行为设置（事件的设置:blur、change）
        sence: [{ required: true, message: '请选择显示场景', trigger: 'change' }]
      }
    }
  },
  // 组件挂载完毕发请求
  mounted () {
    // 获取列表数据方法
    this.getList()
  },
  methods: {
    getList () {
      getAdGoodsListRequest(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取规则列表失败', 'error')
        }
        this.list = result.data.list
        for (const item of this.list) {
          if (item.icon.includes('http')) {
            item.imageUrl = item.icon
          } else {
            item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.icon
          }
        }
        console.log('hunt goods:', this.list)
        this.total = result.data.total
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getList()
    },
    changeAdGoodsState (item) {
      updateAdGoodsStatusRequest({
        id: item.id,
        state: item.state
      }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改状态失败', 'error')
        }
        this.alertMessage('修改状态成功', 'success')
        this.getList()
      })
    },
    update (row) {
      console.log('update row:', row)
      this.dialogFormVisible = true
      this.form = { ...row }
    },
    add2Srv () {
    },
    update2Srv () {
      console.log('this---s form:', this.form)
      updateAdGoodsRequest(this.form).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改显示场景失败', 'error')
        }
        this.alertMessage('修改显示场景成功', 'success')
        this.getList()
      })
    },
    addOrUpdate () {
      this.$refs.form.validate(valid => {
        if (!valid) return
        this.dialogFormVisible = false
        if (this.form.id) {
          this.update2Srv()
        } else {
          this.add2Srv()
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
